<template>
  <div>
    <h3 class="mb-2 mt-1">
      Wypełnij ankietę oceniając swoje samopoczucie
    </h3>
    <Quiz
        v-if="quiz"
        :quiz="quiz"
        :refillable="true"
    />
  </div>
</template>

<script>
import {BButton, BCard, BCardText, BImg, BLink, BCol, BRow} from 'bootstrap-vue'
import { email, required } from '@core/utils/validations/validations'
import Quiz from "@/views/quiz/Quiz.vue";

export default {
  components: {
    Quiz,
    BButton,
    BImg, BLink,
    BCard,
    BCardText,
    BCol,
    BRow
  },
  data() {
    return {
      quiz: null
    }
  },
  created() {
    this.fetchQuiz()
  },
  computed: {
  },
  methods: {
    fetchQuiz() {
      this.$http.get(`${this.$store.state.apiDomain}/api/quiz`).then(response => {
        this.quiz = response.data.quiz
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>